<template>
  <el-form :model="form" :rules="rules" ref="ruleForm">
    <div class="row tradingPlatform">
      <el-row :gutter="60">
        <el-col :xs="24" :sm="12" :md="12">
          <div class="labelTitle">
            <span class="required_icon">*</span>
            <label for="">{{ $t('register.accountConfig.chooseTradingPlatform') }}</label>
          </div>
          <el-form-item prop="tradingPlatform">
            <SelectForm v-model="form.tradingPlatform" name="platform" :disabled="disableField">
              <el-option
                v-for="(item, index) in $config.openLiveAccount.choosePlatform(regulator)"
                :key="index"
                :value="item.value"
                :label="item.name"
                :data-testid="item.value"
              ></el-option>
            </SelectForm>
          </el-form-item>
        </el-col>

        <el-col :xs="24" :sm="12" :md="12">
          <div class="labelTitle">
            <span class="required_icon">*</span>
            <label for="">{{ $t('register.accountConfig.chooseAccType') }}</label>
          </div>
          <el-form-item prop="accountType">
            <SelectForm v-model="form.accountType" name="accountType" :disabled="disableField">
              <el-option
                v-for="(item, index) in accountTypeList"
                :key="index"
                :value="item.value"
                :label="item.label"
                :data-testid="item.label"
              ></el-option>
            </SelectForm>
          </el-form-item>
          <div class="pamm_tips" v-show="form.accountType === 'PAMM'">{{ $t('openAdditAcc.pammTips') }}</div>
        </el-col>

        <el-col :xs="24" :sm="12" :md="12">
          <div class="labelTitle">
            <span class="required_icon">*</span>
            <label for="">{{ $t('register.accountConfig.chooseAccCurrency') }}</label>
          </div>
          <el-form-item prop="currency">
            <SelectForm v-model="form.currency" name="currency" :disabled="disableField">
              <el-option
                v-for="item in $config.openLiveAccount.chooseCurrency(regulator, countryCode)"
                :key="item.currency"
                :value="item.currency"
                :label="item.value"
                :data-testid="item.currency"
              ></el-option>
            </SelectForm>
          </el-form-item>
        </el-col>
      </el-row>
    </div>

    <div class="tnc">
      <div class="checkStatus">
        <el-checkbox
          v-model="checkStatus"
          :disabled="disableField"
          :label="$t('openAdditAcc.byTicking')"
          data-testid="checkbox"
        ></el-checkbox>
        <p class="error" v-if="!checkStatus">{{ $t('common.formValidation.tnc') }}</p>
      </div>

      <ul
        dir="ltr"
        v-html="
          $t('openAdditAcc.svg.disclaimer', {
            platform: $config.info.fullName,
            platformEntityName: $config.info.entityName,
            regulatorUrl: GLOBAL_DOMAIN_WEBSITE
          })
        "
      ></ul>
    </div>
  </el-form>
</template>

<script>
import accountConfiguration from '@/mixins/register/accountConfiguration';

export default {
  mixins: [accountConfiguration]
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/register/accountConfiguration.scss';
</style>
