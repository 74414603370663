import { apiGetData } from '@/resource/register'

export default {
  data() {
    return {
      form: {
        tradingPlatform: 'mt4',
        accountType: null,
        currency: null,
        step: 4,
      },
      rules: {
        tradingPlatform: [
          {
            required: true,
            trigger: 'change',
            message: this.$t('openAdditAcc.platErr'),
          },
        ],
        accountType: [
          {
            required: true,
            trigger: 'change',
            message: this.$t('openAdditAcc.accTypeErr'),
          },
        ],
        currency: [
          {
            required: true,
            trigger: 'change',
            message: this.$t('openAdditAcc.currError'),
          },
        ],
      },
      checkStatus: false,
    }
  },
  mounted() {
    this.getUserDate()
  },
  watch: {
    'form.tradingPlatform'() {
      if (!this.disableField) {
        this.form.accountType = null
        this.form.currency = null
      }
    },
    'form.accountType'() {
      if (!this.disableField) {
        this.form.currency = null
      }
    },
  },
  methods: {
    getUserDate() {
      apiGetData(this.form.step).then(resp => {
        if (resp.data.code == 0) {
          this.form = { ...this.form, ...resp.data.data }
          if (this.form.tradingPlatform && this.form.accountType && this.form.currency) this.checkStatus = true
        }
      })
    },
  },
  computed: {
    countryCode() {
      return this.$store.state.common.countryCode
    },
    disableField() {
      return this.$store.state.register.disableField
    },
    accountTypeList() {
      return this.$config.openLiveAccount.mt4RegisterChooseType(this.regulator)
    },
  },
}
